import { create } from 'zustand'

export interface SearchFacetStore {
  facets: any
  setFacets: (indexName: string, facetValues: any) => void
  clearAllFacets: () => void
}

const useSearchFacetStore = create<SearchFacetStore>()((set) => ({
  facets: {},
  setFacets: (indexName, facetValues) =>
    set((state) => ({
      facets: {
        ...state.facets,
        [indexName]: facetValues,
      },
    })),
  clearAllFacets: () =>
    set(() => ({
      facets: {},
    })),
}))

export { useSearchFacetStore }
